/* styles.css */
.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px; /* Ajustez la hauteur selon vos besoins */
}

/* SelectedDate.css */
.centered-text {
    text-align: center;
  }
  