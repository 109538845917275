/* Ajoutez ces styles dans votre fichier CSS global ou dans le composant MyCalendar.css si vous préférez */
.reservation-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    max-width: 400px; /* Ajustez la valeur selon vos préférences */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .reservation-details {
    display: flex;
    flex-direction: column;
  }
  
  .reservation-detail {
    margin-bottom: 8px;
  }
  
  .delete-button {
    background-color: #ff5757;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #e04141;
  }
  
  .selected-date {
    margin-top: 10px; /* Ajustez la marge selon vos besoins */
  }