body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Votre police Google Fonts', sans-serif;
  margin: 0;
  padding: 0;
  background-color: 'Votre couleur de fond';
}

.calendar-container,
.counter-container {
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: 'Votre couleur de bouton';
  color: 'Votre couleur de texte de bouton';
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: 'Votre couleur de survol de bouton';
}
